.BlackJack {
    width: 100%;
    height: 57%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Image {
    width: 100%;
    height: 100%;
}

.Text {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 50%;
    transform: translateY(-60%);
    width: 9em;
}

.TotalBet {
    color: var(--lc-vw-light-brown);
    font-size: var(--medium-font);
    font-family: var(--roboto-bold);
}
.PlayersAndSeats {
    height: 1em;
    font-size: var(--medium-font4);
}
