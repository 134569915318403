p {
    margin: 0;
}
.TableWrapper {
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--lc-vw-grey-dark);
    border-radius: 0.5em;
    overflow: hidden;
    position: relative;
}

.Header {
    display: flex;
    height: 25%;
    justify-content: space-between;
    padding: 0 2%;
    font-family: var(--roboto-regular);
}

.BorderedContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 0.5em;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
    }
    &:after {
        top: 57%;
        transform: translateY(-57%);
        height: 61%;
        background: var(--lc-vw-black-blue);
    }
}
.MistakeText {
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: var(--xlarge-font);
    font-family: var(--roboto-bold);
    text-align: center;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 50%;
    top: 50%;
    transform: translateY(-60%);
    z-index: 10;
    color: var(--lc-vw-red);
}

.ErrorBorderedContainer {
    border: 0.14em solid var(--lc-vw-red);
}

.ErrorTableWrapper {
    box-shadow: 0 0 10px var(--lc-vw-red);
}

.GameId {
    word-break: keep-all;
    margin-right: 5%;
    display: flex;
    align-items: center;
    width: 50%;
    color: var(--lc-vw-white);
    font-size: var(--medium-font2);

    :first-child {
        color: var(--lc-vw-light-brown);
    }
    :last-child {
        margin-left: 5%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.GameName {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--lc-vw-light-brown);
    font-size: var(--medium-font2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.TableNamWrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Footer {
    background: var(--lc-vw-grey-light);
    padding: 0 2%;
    height: 18%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-family: var(--roboto-regular);
}
.ErrorFooter {
    background-color: var(--lc-vw-red);
}

.DealerNameWrapper {
    color: var(--lc-vw-white);
    word-break: break-word;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.DealerAndPitbossesWrapper {
    color: var(--lc-vw-grey);
    font-size: var(--small-font);
}
.ErrorDealerAndPitbossesWrapper {
    color: var(--lc-vw-white);
}

.Text {
    font-size: var(--medium-font3);
    height: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.PitbossesWrapper {
    color: var(--lc-vw-white);
    word-break: break-word;
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
