.Main {
    width: calc(100% - 350px);
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.Container {
    overflow: auto;
    display: flex;
    align-content: flex-start;
    margin: 10px;
    background-color: var(--bg-color);
    flex-wrap: wrap;
}

.SideBar {
    flex-direction: column;
    width: 350px;
    margin-top: 4em;
    margin-bottom: 14px;
    border-radius: 0 0 0 0.5em;
    overflow: auto;
    background: var(--lc-vw-grey-dark);
}

/* scrollbars */

.SideBar::-webkit-scrollbar {
    width: 0.3em;
    background-color: var(--lc-vw-grey-dark_100);
}

.SideBar::-webkit-scrollbar-thumb {
    border-radius: 3em;
    box-shadow: inset 0 0 6px var(--lc-vw-grey-dark_200);
    background-color: var(--lc-vw-grey-dark_80);
}

.SideBar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--lc-vw-grey-dark_200);
    background-color: var(--lc-vw-grey-dark_100);
}
