:root {
    --bg-color: #525252;
    --lc-vw-grey-dark: #1a1a1a;
    --lc-vw-black-blue: rgba(0, 0, 0, 0.6);
    --lc-vw-white: #ffffff;
    --lc-vw-grey-dark_200: rgba(0, 0, 0, 0.1);
    --lc-vw-grey-dark_100: rgba(38, 38, 38, 0.6);
    --lc-vw-grey-dark_80: rgba(255, 255, 255, 0.1);
    --lc-vw-grey-light: #333333;
    --lc-vw-grey: #969696;
    --lc-vw-grey-100: #5f5f5f;
    --lc-vw-grey-rider: #2c2c2c;
    --lc-vw-orange: #dc9306;
    --lc-vw-light-brown: #d3a061;
    --lc-vw-green: #048804;
    --lc-vw-red: #d62f0f;
    --small-font: 0.5em;
    --medium-font: 1em;
    --medium-font4: 0.6em;
    --medium-font3: 0.7em;
    --medium-font2: 0.8em;
    --xlarge-font: 1.5em;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: var(--medium-font);
    background: var(--bg-color);
}
.App {
    display: flex;
    padding: 0;
    height: 100%;
}
