.SicBo {
    width: 100%;
    height: 57%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Image {
    width: 100%;
    height: 100%;
}

.Text {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    right: 50%;
    width: 48%;
    top: 50%;
    transform: translate(50%, -80%);
}

.ViewersPlayers {
    height: 1em;
    font-size: var(--medium-font4);
}

.TotalBet {
    color: var(--lc-vw-light-brown);
    font-size: var(--medium-font);
    font-family: var(--roboto-bold);
}
