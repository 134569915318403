.Header {
    font-size: 20px;
    position: fixed;
    display: flex;
    align-items: center;
    align-content: center;
    width: 350px;
    background-color: var(--lc-vw-grey-rider);
    height: 2.5em;
    color: var(--lc-vw-grey);
    top: 14px;
    border-radius: 8px 0 0 0;
}

.Title {
    margin-left: 1em;
    font-family: var(--roboto-regular);
}

.AllElement {
    font-family: var(--roboto-bold);
    color: var(--lc-vw-white);
}

.NoActiveIssues {
    color: var(--lc-vw-white);
    margin-left: 0.3em;
}

.IsActive {
    font-family: var(--roboto-bold);
    color: var(--lc-vw-red);
}
