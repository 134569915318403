.MistakeData {
    display: flex;
    flex-direction: column;
    padding: 1em;
    border-bottom: 1px solid var(--lc-vw-grey);
    font-family: var(--roboto-regular);
}

.TimeMistakeType {
    font-family: var(--roboto-bold);
    margin: 0.2em;
}
.GameName {
    color: var(--lc-vw-grey-100);
    margin: 0.2em;
}
.DealerName {
    margin: 1em 0.2em 0 0.2em;
    color: var(--lc-vw-grey);
}
.PitBosses {
    margin: 0.2em;
    color: var(--lc-vw-grey);
}
